import React, {useState, useEffect} from "react";
import restAPI from "../../../features/restAPI";
import dayjs from "dayjs";

import {LinkOutlined, FilePdfOutlined, CloseOutlined, WarningOutlined, CheckSquareOutlined} from "@ant-design/icons";
import {
    Button, DatePicker, Space, Typography, Row, Col, Select, Modal, Alert, Drawer, Form, Divider, Tooltip, Spin, Tag
} from "antd";
import {FinalizedOrdersTable} from "./styled-components/FinalizedTable";

import {LOGO_EFUNC, VIT_LAB} from '../../../assets/images';
import styled from "styled-components";

// hardcoded Courmed products
const courmedProducts = [
    {
        "id": 1,
        "name": "Essentials Vitamins & Minerals",
        "price": 374,
        "code": "ME",
        "ordersCode": "EF.07",
        "travalabLink": "https://travalab.com/courmed-essential",
    },

    {
        "id": 2,
        "name": "Advanced Essentials + Hormones",
        "price": 674,
        "code": "MP",
        "ordersCode": "EF.08",
        "travalabLink": "https://travalab.com/courmed-plus",
    },

    {
        "id": 3,
        "name": "Comprehensive Advanced + Metabolic ++",
        "price": 1125,
        "code": "MC",
        "ordersCode": "EF.09",
        "travalabLink": "https://travalab.com/courmed-comprehensive",
    },
];


const ElaborateReportModal = styled(Modal)`
  .ant-modal-content {
    padding: 0 !important;
    z-index: 999 !important;

    .ant-modal-content::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #F5F5F5;
    }

    .ant-modal-content::-webkit-scrollbar {
      width: 10px;
      background-color: #F5F5F5;
    }

    .ant-modal-content::-webkit-scrollbar-thumb {
      background-color: #000000;
      border: 2px solid #555555;
    }
  }
`


const PatientDetails = ({authConfig, productsList, patient, setIsLoading}) => {

    const pagination = {position: ["bottom"],};  // table config
    const [kits, setKits] = useState([]);


    // eslint-disable-next-line
    const [month, setMonth] = useState(null);
    const [filterByKits, setFilterByKits] = useState(null);

    // modal and iframe stuf
    const [open, setOpen] = useState(false);  // report modal state
    const [selectedKIT, setSelectedKIT] = useState(null);
    const [isReportLoading, setIsReportLoading] = useState(false);
    const [iframeLink, setIframeLink] = useState(null);
    const [reportError, setReportError] = useState(null);

    // vitamin drawer
    const [isDrawerOpened, setIsDrawerOpened] = useState(false);
    const [isCreateOrderLoading, setIsCreateOrderLoading] = useState(false);
    const [kind, setKind] = useState(null);  // kind of vitamins
    const [daysSupply, setDaysSupply] = useState(null);  // vitamins days supply

    // Declined kit  modal
    const [openDeclinedModal, setOpenDeclinedModal] = useState(false);


    const showDeclinedModal = (currentKit) => {
        setSelectedKIT({...currentKit})
        setOpenDeclinedModal(true);
    };

    const handleDeclinedModalOk = () => {
        handleDeclinedModalCancel();
    };

    const handleDeclinedModalCancel = () => {
        // console.log('Clicked cancel button');
        setOpenDeclinedModal(false);
    };


    const showModal = (currentKit) => {
        setSelectedKIT({...currentKit})
        setOpen(true);
    };

    const handleOk = () => {
        handleCancel();
    };

    const handleCancel = () => {
        // console.log('Clicked cancel button');
        setOpenDeclinedModal(false);
        setOpen(false);
        setSelectedKIT(null);
        setIframeLink(null);
        setReportError(null);
    };

    // table columns editing here
    const columns = [

        {
            title: 'Test',
            dataIndex: 'orderItem',
            responsive: ['xs', 'sm'],
            render: (orderItem, currentKit) =>
                <>
                    {courmedProducts.filter(p => p?.code === currentKit?.test)[0].name}
                </>
        },

        {
            title: 'Barcode', dataIndex: 'barcode', responsive: ['md'],
        },


        {
            title: 'Status', dataIndex: 'status', responsive: ['xs', 'md'], render: status =>
                <>
                    {status === "MDDecline" ?
                        <span style={{color: "red"}}><WarningOutlined/>&nbsp;Declined</span>
                        : status === "LabDecline"
                            ? <span style={{color: "red"}}><WarningOutlined/>&nbsp;Declined</span>
                            : status === 'Final'
                                ? <span style={{color: "#605484"}}><CheckSquareOutlined/>&nbsp;{status}</span>
                                : status
                    }
                </>
        },

        {
            title: 'Activation Date',
            dataIndex: 'dateActivated',
            render: date => <>{dayjs(date).format("MM-DD-YYYY")}</>,
            responsive: ['xl',],
        },

        {
            title: 'Final Date', dataIndex: 'finalDate', render: date => <>
                {dayjs(date).isValid() ? dayjs(date).format("MM-DD-YYYY") : 'TBD'}
            </>, responsive: ['xl',],
        },

        {
            title: 'Results', dataIndex: 'created', render: (finalDate, currentKit) =>

                <Row justify={"center"}>

                    {currentKit.status === 'LabDecline'

                        ? <Tooltip placement="left" title={'Reason explanation'} zIndex={10}>
                            <Button
                                danger
                                color={'danger'}
                                icon={<WarningOutlined/>}
                                onClick={() => showDeclinedModal(currentKit)}
                            />
                        </Tooltip>

                        : currentKit.status === 'MDDecline'

                            ? <Tooltip placement="left" title={'Reason explanation'} zIndex={10}>
                                <Button
                                    danger
                                    // type={'primary'}
                                    icon={<WarningOutlined/>}
                                    onClick={() => showDeclinedModal(currentKit)}
                                />
                            </Tooltip>

                            : currentKit.test === "MN" && currentKit.status === 'Final'


                                ? <>

                                    {/* TODO remove if we not sell vitamins on Patient Portal. VitaminsLabs results*/}
                                    {/*{currentKit?.vitamins === null || currentKit?.vitamins?.length === 0 ?*/}
                                    {/*    <Tooltip placement="left" title={'Preparing report'}>*/}
                                    {/*        <SyncOutlined spin={true}/>*/}
                                    {/*    </Tooltip>*/}

                                    {/*    : <>*/}
                                    {/*        <Tooltip placement="left" title={'Vitamins list'}>*/}
                                    {/*            <Button icon={<ProfileOutlined/>} onClick={() => showDrawerHandler(currentKit)}/>*/}
                                    {/*        </Tooltip>*/}

                                    {/*        <Tooltip placement="right" title={'Full report'}>*/}
                                    {/*            <Button icon={<LinkOutlined/>} onClick={() => showModal(currentKit)}/>*/}
                                    {/*        </Tooltip>*/}
                                    {/*    </>}*/}

                                    <Tooltip placement="left" title={'Full report'} zIndex={10}>
                                        <Button icon={<LinkOutlined/>} onClick={() => showModal(currentKit)}/>
                                    </Tooltip>

                                </>

                                : (currentKit.test === "FS" && currentKit.test === "FE") && currentKit.status === 'Final' ?
                                    <Button
                                        icon={<FilePdfOutlined/>}
                                        onClick={() => showModal(currentKit)}
                                    />

                                    : currentKit.test !== "MN" && currentKit.test !== "FE" && currentKit.status === 'Final' ?
                                        <Tooltip placement="left" title={'Full report'} zIndex={10}>
                                            <Button icon={<LinkOutlined/>} onClick={() => showModal(currentKit)}/>
                                        </Tooltip> : <span>Pending</span>


                    }
                    {/*</Space>,*/}
                </Row>, responsive: ['xs', 'sm', 'md',],
        },

    ];

    const loadPatientsKits = async () => {

        setIsLoading(true);

        try {

            let requestURL = ``; // result API request URL

            // month and kits filter no null and filter not === `all` -> it's mean we must filter for all params
            if (month !== null && filterByKits !== null && filterByKits !== 'all') {
                requestURL = `/kits/all/?patient=${patient.id}&ordering=-created`;
                requestURL += `&test=${filterByKits}`;
                requestURL += `&created__gte=${month.startDate}`;
                requestURL += `&created__lte=${month.endDate}`;
            }

            // only month filter turn ON
            if (filterByKits === null && month !== null) {
                requestURL = `/kits/all/?patient=${patient.id}&ordering=-created`;
                requestURL += `&created__gte=${month.startDate}`;
                requestURL += `&created__lte=${month.endDate}`;
            }

            // only test filter turn ON date - OFF
            if (filterByKits !== null && month === null) {
                requestURL = `/kits/all/?patient=${patient.id}&ordering=-created`;
                requestURL += `&test=${filterByKits}`;
            }

            // Date filter ON and when we have `all` -> selected
            if (month !== null && filterByKits === 'all') {
                requestURL = `/kits/all/?patient=${patient.id}&ordering=-created`;
                requestURL += `&created__gte=${month.startDate}`;
                requestURL += `&created__lte=${month.endDate}`;
            }

            // Date turn OFF and
            if (month === null && filterByKits === 'all') {
                requestURL = `/kits/all/?patient=${patient.id}&ordering=-created`;
            }

            // Date turn OFF and testFiltering OFF
            if (filterByKits === null && month === null) {
                requestURL = `/kits/all/?patient=${patient.id}&ordering=-created`;
            }

            const response = await restAPI.get(`${requestURL}`, authConfig)

            setKits(response.data.results);

        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }

    const onChangeMonthHandler = value => {
        const currentDate = dayjs(value);

        if (currentDate.isValid()) {
            const startDate = currentDate.startOf('month').format('YYYY-MM-DD');
            const endDate = currentDate.endOf('month').format('YYYY-MM-DD');

            setMonth({startDate, endDate});
            return;
        }
        setMonth(null);
    }

    useEffect(() => {
        (async () => {
            await loadPatientsKits();
        })();
        // eslint-disable-next-line
    }, [filterByKits, month]);


    const getReportLink = async () => {
        /* Elaborate Report */
        const {id, patient, finalDate} = selectedKIT;

        const data = {
            "kitID": id, "patientID": patient, "doctorNPI": "114918349",  // for Patient, we can check only Patient info.
            finalDate
        };

        try {
            setIsReportLoading(true);

            const response = await restAPI.post(`/results/get-report-link/`, data, authConfig)

            // console.log(response.data.secureURL);

            setIframeLink(response.data.secureURL);

        } catch (error) {

            if (error.hasOwnProperty('response')) {
                setReportError(error.response.data.error);
            }

            console.log(error);

        } finally {
            setIsReportLoading(false);
        }

    }


    useEffect(() => {

        if (selectedKIT && open) {

            (async () => {
                await getReportLink();
            })();

        }


    }, [open])


    const handleChange = (value, object) => {
        // console.log(`selected ${object.code}`); // I can use this code for filtering

        setFilterByKits(object.code);
    };

    const showDrawerHandler = currentKIT => {
        setSelectedKIT(currentKIT);
        setIsDrawerOpened(true);
    }

    const onDrawerCloseHandler = () => {
        // console.log('Clicked cancel button');
        setIsDrawerOpened(false);
        setSelectedKIT(null);
        setKind(null);
        setDaysSupply(null);
    }

    const kindChangeHandler = state => {
        // console.log(state);
        setKind(state);
    }

    const daysSupplyChangeHandler = state => {
        // console.log(state);
        setDaysSupply(state);
    }


    const makeNewVitaminLabsOrder = async action => {
        /*
        -> make request for creating a new order
        -> after api successfully answer
        -> redirect user to `VitaminLabs` with orderID in params */

        // console.log(action);


        const data = {
            "partner_uuid": selectedKIT?.id,
            "kind": kind || "capsules",
            "days_supply": parseInt(daysSupply),
            "provider_id": selectedKIT?.provider
        }

        try {

            setIsCreateOrderLoading(true);

            const response = await restAPI.post('igs-vitamins/make-vitaminlab-order/', data, authConfig);

            console.log(response.data.orderID);

            if (action === 'buy') {
                window.open(`https://wp-staging.getvitaminlab.com/efunctional-portal/?buy_now=true&formula_id=${response.data.orderID}`)
            } else if (action === 'modify') {
                window.open(`https://wp-staging.getvitaminlab.com/efunctional-portal/?formula_id=${response.data.orderID}`)
            }

        } catch (error) {

            console.log(error);

            // TODO make errors alerts and show user problem

        } finally {
            setIsCreateOrderLoading(false);
            onDrawerCloseHandler();  // destroy child's state too
        }

    }

    return <Row gutter={[24, 24]}>


        <Drawer
            destroyOnClose={true}
            bodyStyle={{width: '100%'}}
            size={'large'}
            placement={'right'}
            closeIcon={<CloseOutlined/>}
            // title={`${selectedPatient?.last_name} ${selectedPatient?.first_name}`}
            width={window.innerWidth > 1200 ? '38%' : '92%'}
            closable={window.innerWidth <= 1200}
            onClose={onDrawerCloseHandler}
            open={isDrawerOpened}
        >
            <Row gutter={[12, 12]}>

                <Col span={'24'}>
                    <Row justify={'space-between'}>
                        <Col span={12}>
                            <img src={LOGO_EFUNC} alt="EFunctional logo" style={{width: '45px'}}/>
                        </Col>

                        <Col span={12}>
                            <img src={VIT_LAB} alt="VitaminLab logo"
                                 style={{width: '50%', float: 'right'}}/>
                        </Col>
                    </Row>
                </Col>

                <Col span={'24'}>
                    <Typography.Title
                        style={{
                            fontSize: 21,
                            fontWeight: 400,
                            textTransform: 'uppercase',
                            margin: '30px 0',
                            fontFamily: 'Montserrat',
                            color: '#353c4e'
                        }}>
                        Micronutrient Order Form
                    </Typography.Title>
                    <Divider style={{margin: '-30px 0 20px 0'}}/>
                </Col>

                <Col span={'24'}>
                    <Typography.Title style={{fontSize: 14, fontWeight: 400, textTransform: 'uppercase'}}>
                        <strong>ORDER OPTIONS</strong>
                    </Typography.Title>
                </Col>

                <Col span={'24'}>
                    <Form.Item name="supply" label="Supply">
                        <Select
                            value={kind}
                            placeholder={'How much days?'}
                            style={{width: '200px'}}
                            onChange={daysSupplyChangeHandler}
                            options={[{value: '90', label: '90 Days',}, {
                                value: '120', label: '120 Days',
                            }, {value: '180', label: '180 Days',},]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item name="doseType" label="Dose Type">
                        <Select
                            value={daysSupply}
                            placeholder={'What kind?'}
                            style={{width: '200px'}}
                            onChange={kindChangeHandler}
                            options={[{value: 'pills', label: 'Pills',}, {
                                value: 'capsules', label: 'Veggie Capsules',
                            },]}
                        />
                    </Form.Item>

                </Col>

                <Divider style={{margin: '-20px 0 20px 0'}}/>

                <Col span={24}>
                    <Typography.Title style={{fontSize: 14, fontWeight: 400, textTransform: 'uppercase'}}>
                        <strong>YOUR CUSTOM SUPPLEMENTS</strong>
                    </Typography.Title>
                </Col>

                <Col span={24}>
                    {selectedKIT?.vitamins.map(vitamin => <Row
                        style={{fontFamily: 'Montserrat', margin: '0 0 7px 0'}}>
                        <Col span={12} key={vitamin.id}>
                            <div>{vitamin.suggested}</div>
                        </Col>

                        <Col span={12}>
                            <strong>{vitamin.calc} {vitamin.frequency}</strong>
                        </Col>
                    </Row>)}
                </Col>


                <Divider/>


                <Col span={10} offset={8}>
                    <Space>
                        <Button
                            onClick={async () => await makeNewVitaminLabsOrder('buy')}
                            disabled={isCreateOrderLoading || kind === null || daysSupply === null}
                        >
                            Buy Now
                        </Button>

                        <Button
                            type={'primary'}
                            onClick={async () => await makeNewVitaminLabsOrder('modify')}
                            disabled={isCreateOrderLoading || kind === null || daysSupply === null}
                        >
                            Modify & Buy
                        </Button>
                    </Space>
                </Col>

            </Row>

        </Drawer>

        {/*Decline modal */}
        <Modal
            centered={true}
            destroyOnClose={true}
            closable={true}
            width={window.innerWidth > 1200 ? '23%' : '92%'}
            open={openDeclinedModal}
            onOk={handleDeclinedModalOk}
            onCancel={handleDeclinedModalOk}
            footer={false}
        >

            <Row justify={"center"} gutter={[24, 24]} style={{padding: "20px"}}>
                <Col>
                    {/*<Alert*/}
                    {/*    style={{margin: "30px 0 0 0", textAlign: "center"}}*/}
                    {/*    message={"Hello I'm a explanation - why you Kit declined!"}*/}
                    {/*/>*/}

                    <p>
                        <strong>
                            Dear {patient?.last_name} {patient?.first_name},
                        </strong>
                    </p>


                    <p>We are very sorry, but your test kit was canceled.</p>

                    <p>
                        At the moment, we do not know the reason for the refusal, but we will definitely find out and
                        inform
                        you about it to your mail.
                    </p>

                    <p>We also sent you instructions for returning funds or re-ordering.</p>


                    <p>
                        Please feel free to reach out to our customer care team with any questions.
                    </p>

                    <p>
                        <strong> All the best, <br/>Efunctional Customer Care Team</strong> <br/>
                        <strong>email</strong>
                        <Button type={'link'} href="mailto:support@efunctional.com">support@efunctional.com</Button>
                        <br/>
                        <strong>phone</strong>
                        <Button type={'link'} href="tel:+18882711717">+1-888-271-1717</Button> <br/>
                        (available M-F 9am - 5pm est)
                    </p>


                </Col>
            </Row>

            <Row justify={"center"} style={{padding: "0 0 20px 0"}}>
                <Button
                    size={'small'}
                    type={'primary'}
                    onClick={handleCancel}
                >
                    I got it
                </Button>
            </Row>

        </Modal>
        {/*Decline modal */}


        {/*Elaborate Modal with IFrame ****---- START ----**** */}
        <ElaborateReportModal
            centered={true}
            destroyOnClose={true}
            closable={true}
            width={reportError ? window.innerWidth > 1200 ? '20%' : '92%' : '95%'}
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={false}
        >

            {reportError && <Spin spinning={isReportLoading}>
                <Row justify={"center"} gutter={[24, 24]} style={{padding: "20px"}}>
                    <Col>
                        <Alert style={{margin: "30px 0 0 0", textAlign: "center"}}
                               message={reportError}/>
                    </Col>
                </Row>
            </Spin>}

            {iframeLink && <iframe

                width={'100%'}
                height={'790px'}
                loading={isReportLoading}
                title={'Elaborate report'}
                src={iframeLink}
            />}

            <Row justify={"center"} style={{padding: "0 0 20px 0"}}>
                <Button
                    icon={reportError ? null : <CloseOutlined/>}
                    size={'small'}
                    type={reportError ? 'primary' : 'default'}
                    onClick={handleCancel}
                >
                    {reportError ? 'Okay' : 'Close report'}
                </Button>
            </Row>

        </ElaborateReportModal>
        {/*Elaborate Modal with IFrame ****---- END ----**** */}

        <Col xs={24} lg={8}>
            <Typography.Text>
                Filter your results:
                {/*<span style={{fontSize: 8}}>*/}
                {/*    {`${month?.startDate} - ${month?.endDate} + ${filterByKits}`}*/}
                {/*</span>*/}
            </Typography.Text>
        </Col>

        <Col xs={24} lg={8}>
            <DatePicker
                placeholder={'Filter by month'}
                style={{width: '100%'}}
                // defaultValue={dayjs()}
                onChange={onChangeMonthHandler}
                format={'MM-YYYY'}
                picker="month"
                inputReadOnly={true}
                size={'small'}
            />
        </Col>

        <Col xs={24} lg={8}>
            <Select
                size={'small'}
                style={{width: '100%'}}
                placeholder={'Filter by test'}
                onChange={handleChange}
                options={productsList}
            />
        </Col>

        <FinalizedOrdersTable
            style={{padding: '0 10px'}}
            sticky={true}
            size={'small'}
            columns={columns}
            dataSource={kits}
            pagination={pagination}
            scroll={false}
        />

    </Row>;
}

export default PatientDetails;