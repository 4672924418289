import React, {useEffect, useContext} from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import {AuthContext} from "../AuthContext";


const Logout = () => {
    const {logout} = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        logout();
        navigate('/');
        // eslint-disable-next-line
    }, []);

    return <>

        <div className="container">
            <div className="wrapper">
                <div className="signin">
                    <div className="jbox-dark-sm" style={{height: 'auto'}}>

                        <div className="jbox-dark-header">
                            <p>
                                Logged out
                            </p>
                        </div>

                        <hr className="solid"/>

                        <div className="jbox-dark-paragraph">
                            <p>You are successfully logged out from EFunctional</p>
                            <p>You can login to your account</p>
                            <br/>
                            <br/>

                            <NavLink to={'/login'} style={{color: 'white'}}>
                                <strong>Login page</strong>
                            </NavLink>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </>

}
export default Logout;


