import restAPI from "../../../features/restAPI";
import pages from "../../../routing/config";
import statesJSON from './states.json';

import React, {useState} from 'react';
import {NavLink} from "react-router-dom";

import {Spin} from 'antd';


const phoneNumberMask = value => {
    // old pattern
    // let x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    // return (value = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? "-" + x[3] : ""}`);

    // fixed pattern
    let x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    return (value = !x[2] ? x[1] : `${x[1]}-${x[2]}${x[3] ? "-" + x[3] : ""}`);
}

const dobMask = value => {
    let x = value.replace(/\D/g, "").match(/(\d{0,2})(\d{0,2})(\d{0,4})/);
    return (value = !x[2] ? x[1] : `${x[1]}-${x[2]}${x[3] ? "-" + x[3] : ""}`);
}

const postalCodeMask = value => {
    let x = value.replace(/[^0-9]/g, '');
    return value.replace(/[^0-9]/g, '');
}


const Register = () => {
    const initialState = {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        gender: "Female",
        date_of_birth: "",
        address: "",
        city: "",
        state: "FL",
        zip: "",
        password: "",
        password2: "",
    }

    const states = JSON.parse(statesJSON);

    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState(initialState);  // main form data
    const [isRegistered, setIsRegistered] = useState(false); // switching context if registration data saved
    const [registerError, setRegisterError] = useState(null);  // form and server errors


    const handleChange = event => {
        setFormData({
            ...formData, [event.target.name]: event?.target.value
        });
    }

    const handlePhoneChange = event => {
        const inputValue = event.target.value;
        const formattedPhone = phoneNumberMask(inputValue);

        if (formattedPhone.length <= 14) {
            setFormData({
                ...formData, phone: formattedPhone
            });
        }
    };

    const handleDOBChange = event => {
        const inputValue = event.target.value;
        const prettyDate = dobMask(inputValue);

        if (prettyDate.length <= 10) {
            setFormData({
                ...formData, date_of_birth: prettyDate
            });
        }

    };

    const handlePostalCodeChange = event => {
        const inputValue = event.target.value;

        if (inputValue.length <= 5) {
            setFormData({
                ...formData, zip: inputValue
            });
        }
    };


    const checkEmptyFields = () => {
        for (const value of Object.values(formData)) {
            if (value.length === 0) {
                throw new Error("All fields important and can't be empty");
            }
        }
    }

    const checkPasswords = () => {
        const {password, password2} = formData;

        if (password.length <= 7) throw new Error("Password is too short. Use at least 8 characters")
        if (password !== password2) throw new Error("Password don't match. Please fix it");
    }

    const isValidEmail = () => {
        const {email} = formData;
        const result = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);

        if (!result) throw new Error("Not valid email. Please fix it");
    }

    const checkPhone = () => {
        const {phone} = formData;

        if (phone.length !== 12) throw new Error("Not valid phone number. Please fix it");
    }

    const checkDateOfBirth = () => {
        const {date_of_birth} = formData;

        if (date_of_birth.length !== 10) throw new Error("Not valid Date of birth. Please fix it. Format: MM-DD-YYYY");
    }


    const makeRegistration = async event => {
        event.preventDefault();

        const [m, d, y] = formData?.date_of_birth.split('-');

        // It's important Object view for Backend
        const registerData = {
            partner: "CourMed",
            first_name: formData?.first_name,
            last_name: formData?.last_name,
            email: formData?.email,
            phone: formData?.phone,
            date_of_birth: `${y}-${m}-${d}`, // convert date what django-backend expecting
            gender: formData?.gender,
            user: {
                username: formData?.email,
                password: formData?.password,
                email: formData?.email,
            },
            address_data: {
                address_1: formData?.address,
                city: formData?.city,
                state: formData?.state,
                postcode: formData?.zip,
            }
        }

        // console.log(registerData);  // debug only

        try {

            // console.log("formData", formData);
            //
            // console.log("registerData", registerData);

            checkEmptyFields();  // if any field is empty -> show error
            checkPasswords();  // check matching pass1 and pass2
            isValidEmail();  // validate email
            checkPhone();  // validate phone number
            checkDateOfBirth();  // validate date of birth


            setIsLoading(true);

            await restAPI.post(`/patients/partners-all/`, registerData);

            // console.log(response);  // debug only

            setIsRegistered(true);


        } catch (error) {


            if (error.hasOwnProperty('code') && error?.code === "ERR_BAD_REQUEST") {
                setRegisterError("A user with that username already exist.");
            }

            if (error.hasOwnProperty('message')) {
                setRegisterError(error);
            }

            // console.log(error);

        } finally {

            setIsLoading(false);

        }

    }

    return <div className="container">
        <Spin spinning={isLoading} delay={1}>
            <div className="wrapper">
                <div className="signin">
                    <div className="jbox-dark-md">

                        <div className="jbox-dark-header">
                            <p>Registration</p>
                        </div>

                        <hr className="solid"/>


                        {isRegistered === false ? <>

                                {registerError && <>
                                    <p style={{color: "red", textAlign: "center", background: 'white', padding: '7px 0'}}>
                                        {registerError?.message}
                                    </p>
                                </>}

                                <form
                                    style={{fontFamily: "Montserrat, sans-serif",}}
                                    onSubmit={makeRegistration}
                                    className="input-group-md"
                                    id="register-patient"
                                >
                                    <div className="row">

                                        <div className="column">
                                            <input
                                                value={formData?.last_name || ""}
                                                className="input-field"
                                                type="text"
                                                name="last_name"
                                                placeholder="Last Name"
                                                onChange={handleChange}
                                            />

                                            <input
                                                value={formData?.first_name || ""}
                                                className="input-field"
                                                type="text"
                                                name="first_name"
                                                placeholder="First Name"
                                                onChange={handleChange}
                                            />

                                            <select
                                                value={formData?.gender || "Female"}
                                                className="input-field"
                                                id={'sex'} name="gender"
                                                onChange={handleChange}
                                                placeholder={"Gender"}
                                            >
                                                <option value="Female">Female</option>
                                                <option value="Male">Male</option>
                                            </select>

                                            <input
                                                value={formData?.date_of_birth || ""}
                                                className="input-field"
                                                name="date_of_birth"
                                                placeholder="Date of birth:  mm-dd-yyyy"
                                                onChange={handleDOBChange}
                                            />

                                            <input
                                                value={formData?.email || ""}
                                                className="input-field"
                                                id="email-patient"
                                                type="text"
                                                name="email"
                                                placeholder="Email"
                                                onChange={handleChange}
                                            />


                                            <input
                                                value={formData.phone || ""}
                                                className="input-field"
                                                id="phone-patient"
                                                name="phone"
                                                placeholder="Phone"
                                                onChange={handlePhoneChange}
                                            />


                                        </div>

                                        <div className="column">

                                            <input
                                                value={formData?.address || ""}
                                                className="input-field"
                                                name="address"
                                                placeholder="Address"
                                                onChange={handleChange}
                                            />

                                            <input
                                                value={formData?.city || ""}
                                                className="input-field"
                                                name="city"
                                                placeholder="City"
                                                onChange={handleChange}
                                            />

                                            <select
                                                name="state"
                                                className="input-field"
                                                value={formData.state}
                                                onChange={handleChange}
                                            >
                                                {states.map(state => <option value={state.postal}>
                                                    {state.name}
                                                </option>)}
                                            </select>

                                            <input
                                                minLength={5}
                                                pattern='[0-9]{0,5}'
                                                type={'number'}
                                                value={formData?.zip || ""}
                                                className="input-field"
                                                name="zip"
                                                placeholder="Zip code"
                                                onChange={handlePostalCodeChange}
                                            />

                                            <input
                                                value={formData?.password || ""}
                                                className="input-field"
                                                type="password"
                                                name="password"
                                                placeholder="Password"
                                                onChange={handleChange}
                                            />

                                            <input
                                                value={formData?.password2 || ""}
                                                className="input-field"
                                                type="password"
                                                name="password2"
                                                placeholder="Confirm Password"
                                                onChange={handleChange}
                                            />

                                        </div>

                                        <div className="column-phone">

                                            <input
                                                value={formData?.last_name || ""}
                                                className="input-field"
                                                id="lastName-patient-phone"
                                                type="text"
                                                name="last_name"
                                                placeholder="Last Name"
                                                onChange={handleChange}
                                            />

                                            <input
                                                value={formData?.first_name || ""}
                                                className="input-field"
                                                id="firstName-patient-phone"
                                                type="text"
                                                name="first_name"
                                                placeholder="First Name"
                                                onChange={handleChange}
                                            />


                                            <input
                                                value={formData?.email || ""}
                                                className="input-field"
                                                id="email-patient-phone"
                                                type="text"
                                                name="email"
                                                placeholder="Email"
                                                onChange={handleChange}
                                            />

                                            <input
                                                value={formData?.phone || ""}
                                                className="input-field"
                                                id="phone-patient-phone"
                                                name="phone"
                                                placeholder="Phone"
                                                onChange={handlePhoneChange}
                                            />


                                            <input
                                                value={formData?.gender || ""}
                                                className="input-field"
                                                name="gender"
                                                placeholder="Gender"
                                                onChange={handleChange}
                                            />

                                            <input
                                                value={formData?.date_of_birth || ""}
                                                className="input-field"
                                                name="date_of_birth"
                                                placeholder="Date of birth"
                                                onChange={handleDOBChange}
                                            />

                                            <input
                                                value={formData?.address || ""}
                                                className="input-field"
                                                name="address"
                                                placeholder="Address"
                                                onChange={handleChange}
                                            />

                                            <input
                                                value={formData.city || ""}
                                                className="input-field"
                                                name="city"
                                                placeholder="City"
                                                onChange={handleChange}
                                            />

                                            <select
                                                name="state"
                                                className="input-field"
                                                value={formData.state}
                                                onChange={handleChange}
                                            >
                                                {states.map(state => <option value={state.postal}>
                                                    {state.name}
                                                </option>)}
                                            </select>

                                            <input
                                                type={'number'}
                                                value={formData?.zip || ""}
                                                className="input-field"
                                                name="zip"
                                                placeholder="Zip code"
                                                onChange={handlePostalCodeChange}
                                            />

                                            <input
                                                value={formData?.password || ""}
                                                className="input-field"
                                                id="password-patient-phone"
                                                type="password"
                                                name="password" placeholder="Password"
                                                onChange={handleChange}
                                            />

                                            <input
                                                value={formData?.password2 || ""}
                                                className="input-field"
                                                id="confirm-password-patient-phone"
                                                type="password"
                                                name="password2"
                                                placeholder="Confirm Password"
                                                onChange={handleChange}
                                            />

                                        </div>
                                    </div>

                                    <button
                                        disabled={isLoading}
                                        className="submit-btn"
                                        id="register-btn-patient"
                                        type="submit"
                                    >
                                        Register
                                    </button>


                                    <div className="jbox-dark-paragraph">
                                        <NavLink to={pages.login.url} style={{color: 'white', padding: "0 70px 0 0"}}>
                                            Back to login page
                                        </NavLink>
                                    </div>

                                </form>

                            </>

                            : <>
                                <div className="jbox-dark-paragraph">
                                    <p>
                                        You have successfully created an Efunctional account.
                                    </p>

                                    <p>
                                        The activation link was sent to "{formData?.email}"
                                    </p>
                                </div>
                            </>}


                    </div>
                </div>
            </div>
        </Spin>
    </div>;
}
export default Register;


