import React, {useState, useEffect} from "react";

import {Input, Row, Col, Button, Alert} from 'antd';
import restAPI from "../../../features/restAPI";

const ValidateBarcode = ({isLoading, next, barcode, setBarcode, authConfig, setIsLoading, setDetectedKitInfo}) => {

    const [isNext, setIsNext] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const inputOnChangeHandler = ({target: {value}}) => (setBarcode(value));


    const checkBarcode = async () => {
        setIsLoading(true);

        try {

            // todo create one method on backend. For now it's good.
            const pendingKit = `/kits/all/?status=Pending&partner=CourMed&barcode=${barcode}`;
            const assignedKit = `/kits/all/?status=Assigned&partner=CourMed&barcode=${barcode}`;

            const checkPending = await restAPI.get(pendingKit, authConfig);
            const checkAssigned = await restAPI.get(assignedKit, authConfig);

            console.log(checkPending, checkAssigned)

            // console.log(response.data.results)

            if (checkPending.data.results.length === 0 && checkAssigned.data.results.length === 0) {

                let error_message = <>
                    <p>
                        Hmmm...We can't seem to find barcode - <strong>{barcode}</strong>.
                    </p>

                    <p>
                        Please double check to make sure the correct barcode was input.
                    </p>

                    <p>
                        If this persists, please give our care team a call @ 888-271-1717
                    </p>
                </>

                setErrorMessage(error_message);
                throw Error(error_message);

            } else if (checkPending.data.results.length !== 0) {

                const [detectedKit] = checkPending.data.results;

                setDetectedKitInfo(detectedKit);  // save detectedKit -> to parent component state
                next(); // move to next step after successful and not empty response

            } else if (checkAssigned.data.results.length !== 0) {

                const [detectedKit] = checkAssigned.data.results;

                setDetectedKitInfo(detectedKit);  // save detectedKit -> to parent component state
                next(); // move to next step after successful and not empty response
            }


        } catch (error) {
            console.log(error);

            if (error.hasOwnProperty('response')) {
                setErrorMessage(`Server say error - ${error.response.status}. Not auth or your session are finished!`);
            }

        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {

        if (barcode !== null) {

            if (barcode?.length > 10) {
                setIsNext(true);
            } else {
                setIsNext(false);
            }

        } else {
            setIsNext(false);
        }

    }, [barcode])


    return <Row style={{marginTop: 30}}>
        <Col xs={24} md={10}>
            <p>
                Enter barcode from your kit
            </p>

            <Input placeholder="EF0001-CV001" value={barcode} onChange={inputOnChangeHandler}/>

            <div style={{margin: "24px 0"}}>

                <Button disabled={!isNext} loading={isLoading} type="primary" onClick={checkBarcode}>
                    Next
                </Button>
            </div>

            {errorMessage && <Alert style={{margin: "0 0 30px 0"}} message={errorMessage} type="error"/>}

        </Col>

    </Row>
}

export default ValidateBarcode;