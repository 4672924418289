import {Table} from "antd";
import styled from "styled-components";


const FinalizedOrdersTable = styled(Table)`

  & .ant-pagination-item-active {
    border-color: #605484;

    & a {
      color: #605484;
    }
  }

`

export {FinalizedOrdersTable}