import React, {useContext, useState, useEffect} from "react";
import MainPageTitle from "../../../components/MainPageTitle";


import {AuthContext} from "../../auth/AuthContext";
import restAPI from "../../../features/restAPI";
import pages from "../../../routing/config";
import PatientDetails from "./PatientDetails";

import {Col, List, Row, Skeleton, Spin, Input, message} from "antd";
import {LoadingOutlined} from "@ant-design/icons";


const MyResults = () => {
    const {home} = pages;

    const [isLoading, setIsLoading] = useState(true);
    const {authContext} = useContext(AuthContext); // auth data from current session

    const authConfig = {
        headers: {
            Authorization: `ECL ${authContext?.access}`,
        },
    };


    const [productsList, setProductsList] = useState([]);

    const loadProductList = async () => {
        setIsLoading(true);

        await restAPI.get('/products/', authConfig)
            .then(response => {

                const customProductsForFilteringComponent = response?.data?.results.map(
                    product => ({
                        ...product,
                        label: product?.name,
                        value: product?.name,
                        key: product?.id,
                    }));

                const addShowAll = [{
                    key: 1000,
                    label: 'Show all',
                    value: 'all',
                    code: 'all'
                }, ...customProductsForFilteringComponent];

                setProductsList(addShowAll);

            }).catch(err => {
                console.log(err);
                setProductsList([]);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    useEffect(() => {

        (async () => {
            await loadProductList();
        })();
        // eslint-disable-next-line
    }, []);

    // const [options, setOptions] = useState([]);


    const [open, setOpen] = useState(false);
    const [childrenDrawer, setChildrenDrawer] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const showChildrenDrawer = () => {
        setChildrenDrawer(true);
    };
    const onChildrenDrawerClose = () => {
        setChildrenDrawer(false);
    };

    const onClick = ({key}) => {
        message.info(`Click on item ${key}`);
    };

    return <Spin tip="Loading..." spinning={isLoading} indicator={<LoadingOutlined size={'large'}/>}>
        <Row style={{padding: "0 10px"}}>
            <Col xs={24} lg={24} xl={24}>
                <MainPageTitle>{home.title}</MainPageTitle>
            </Col>


            <Col xs={24} lg={24}>

                <PatientDetails
                    authConfig={authConfig}
                    patient={authContext?.patientData}
                    productsList={productsList}
                    open={open}
                    childrenDrawer={childrenDrawer}
                    onClose={onClose}
                    showDrawer={showDrawer}
                    showChildrenDrawer={showChildrenDrawer}
                    onChildrenDrawerClose={onChildrenDrawerClose}
                    onClick={onClick}
                    setIsLoading={setIsLoading}
                />

            </Col>
        </Row>
    </Spin>
}

export default MyResults;