import React, {useState, useEffect, useContext} from "react";
import restAPI from "../../../features/restAPI";
import {LoadingOutlined} from "@ant-design/icons";
import {Col, Row, Input, Form, Button, message, Switch, Spin, Select} from 'antd';
import {AuthContext} from "../../auth/AuthContext";

import statesJSON from './states.json';


const statesArray = JSON.parse(statesJSON).map(s => ({value: s.postal, label: s.name}));

const ShippingInformation = () => {

    const [form] = Form.useForm();
    const [submitErrors, setSubmitErrors] = useState(null);
    const [formState, setFormState] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {
        authContext,
        authConfig,
        updateShippingInfo,
        createShippingInfo
    } = useContext(AuthContext); // auth data from current session


    const submitForm = async (formData) => {

        try {
            const endpoint = "/patients/shipping-addresses/";
            const {id} = authContext?.patientData; // patient id

            const addressesCount = await restAPI.get(`${endpoint}?patient=${id}`, authConfig); // get addresses by id


            // check count === 0, if true -> create new address and update app state
            if (addressesCount?.data.count === 0) {
                const updatedData = {...formData, patient: id, default: true}
                const createdNewAddress = await restAPI.post(`${endpoint}`, updatedData, authConfig);

                // console.log('Make --- `Create new Address`');
                // console.log({...createdNewAddress.data});

                createShippingInfo({...createdNewAddress.data});  // update app global state
            }


            // if we have address or addresses -> find `default` and update it
            else if (addressesCount?.data.count >= 1) {
                // console.log('Make --- `Update default Address`');

                const shippingAddress = authContext?.patientData?.shipping_addresses.find(s => s.default === true);
                const response = await restAPI.patch(`/patients/shipping-addresses/${shippingAddress.id}/`, formData, authConfig);
                updateShippingInfo(response.data); // update app global state

            } else {

                throw new Error("Error. Unpredictable application behavior");

            }

        } catch (error) {

            console.log(error);

            let errors = '';

            for (const [key, value] of Object.entries(error?.response?.data)) {

                console.log(value[0]);

                errors += `${key} - ${value[0]}`;
            }


            setSubmitErrors(errors); // TODO finish it

            throw new Error(errors)


        } finally {
            setIsLoading(false);
        }
    };

    const onFinish = async (values) => {
        /* Submit valid form */

        try {
            setIsLoading(true);

            await submitForm(values);

            setFormState(prevState => !prevState);

            message.success({
                content: `Shipping information updated!`, duration: 3,
            });

        } catch (error) {
            console.log(error);

            message.error({
                content: 'We have an errors!', duration: 2,
            });

        } finally {
            setIsLoading(false);
        }
    }

    const triggerSubmitHandler = () => form.submit();

    const onChange = value => {
        setFormState(value);
    }

    useEffect(() => {
        form.setFieldsValue({
            ...authContext?.patientData.shipping_addresses.find(address => address.default === true)
        });
        // eslint-disable-next-line
    }, []);


    return <Spin tip="Loading..." spinning={isLoading} indicator={<LoadingOutlined size={'large'}/>}>

        <Row style={{padding: "0 10px"}}>
            <Col>
                <Form>
                    <Form.Item
                        label={'Edit mode: '}
                        name="shippingInfoMode"
                    >
                        <Switch checked={formState} onChange={onChange}/>
                    </Form.Item>
                </Form>
            </Col>

            <Col xs={24} lg={24} xl={24}>

                <Form
                    disabled={!formState}
                    layout="vertical"
                    size={'middle'}
                    onFinish={onFinish}
                    form={form}
                    autoComplete="off"
                >

                    <Row gutter={[24, 24]}>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={'Address 1'}
                                name="address_1"
                                rules={[{
                                    required: true, message: 'Please input address 1!',
                                }]}
                            >
                                <Input placeholder="Please input address 1"/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={'Address 2'}
                                name="address_2"
                            >
                                <Input placeholder="Address 2"/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={8}>
                            <Form.Item
                                label={'City'}
                                name="city"
                                rules={[{
                                    required: true, message: 'Please input your city!',
                                }]}
                            >
                                <Input placeholder="City"/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={8}>
                            <Form.Item
                                label={'State'}
                                name="state"
                                rules={[{required: true, message: 'Please input state!'}]}
                            >
                                <Select options={statesArray}/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={8}>
                            <Form.Item
                                label={'Post code'}
                                name="postcode"
                                rules={[
                                    {required: true, message: 'Please input post code!'},
                                    {required: true, min: 5, max: 5, message: 'Postal code length must be equal 5'}
                                ]}
                            >
                                <Input placeholder="12345"/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={24}>
                            <Form.Item
                                label={'Shipping notes'}
                                name="shipping_notes"
                            >
                                <Input.TextArea placeholder="More shipping details if needed"/>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Button
                                hidden={!formState}
                                style={{marginBottom: 50,}}
                                type="primary"
                                loading={false}
                                onClick={triggerSubmitHandler}
                            >
                                Update information
                            </Button>
                        </Col>

                    </Row>
                </Form>
            </Col>
        </Row>
    </Spin>
}

export default ShippingInformation;