import styled from "styled-components";


const Title = styled.h1`
  margin: 35px 0  20px 0;
  font-size: 40px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e0e0e0;
  color: #353c4e;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-weight: 500;


  @media only screen and (max-width: 575px) {
    font-size: 27px;
  }
`


const MainPageTitle = ({children}) => <Title>{children}</Title>


export default MainPageTitle;