import React from "react";
import styled from "styled-components";

const LogoDiv = styled.div`
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const LogoImg = styled.img`
  width: 75%;
  transition: all 0.3s ease;
  transition-delay: 0.1s;

`


const SiderLogo = ({image_source}) =>
    <LogoDiv>
        <LogoImg src={image_source}/>
    </LogoDiv>


export default SiderLogo;

export {
    LogoImg,
}