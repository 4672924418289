import restAPI from "../../../features/restAPI";
import React, {useState, useEffect, useContext} from "react";
import {LoadingOutlined} from "@ant-design/icons";
import {Col, Row, Input, Form, Button, message, Switch, Spin} from 'antd';
import {AuthContext} from "../../auth/AuthContext";
import dayjs from "dayjs";


const phoneNumberMask = value => {
    let x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    return (value = !x[2] ? x[1] : `${x[1]}-${x[2]}${x[3] ? "-" + x[3] : ""}`);
}

const dobMask = value => {
    let x = value.replace(/\D/g, "").match(/(\d{0,2})(\d{0,2})(\d{0,4})/);
    return (value = !x[2] ? x[1] : `${x[1]}-${x[2]}${x[3] ? "-" + x[3] : ""}`);
}


const PersonalInformation = () => {

    const [form] = Form.useForm();
    const [submitErrors, setSubmitErrors] = useState(null);
    const [formState, setFormState] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const {authContext, authConfig, updatePatientData} = useContext(AuthContext); // auth data from current session


    const submitForm = async (formData) => {

        const {date_of_birth} = formData;


        // console.log(date_of_birth);


        const personalData = {...formData, date_of_birth: dayjs(date_of_birth).format('YYYY-MM-DD')}


        // console.log(personalData);
        //
        // return false;

        try {
            setIsLoading(true);

            const response = await restAPI.patch(`/patients/all/${authContext?.patientData?.id}/`, personalData, authConfig)
            updatePatientData(response.data); // update app global state

        } catch (error) {

            console.log(error);

            let errors = '';

            for (const [key, value] of Object.entries(error?.response?.data)) {

                console.log(value[0]);

                errors += `${key} - ${value[0]}`;

                console.log(submitErrors);
            }

            setSubmitErrors(errors); // TODO finish it
            throw new Error(errors)


        } finally {
            setIsLoading(false);
        }
    };

    const onFinish = async (values) => {
        /* Submit valid form */

        try {
            await submitForm(values);
            setFormState(prevState => !prevState);

            message.success({
                content: `Your information successfully updated!`, duration: 2,
            });

        } catch (error) {
            console.log(error);

            message.error({
                content: `We have an errors!`, duration: 5,
            });

        }


        await submitForm(values)
            .then(() => {
                setIsLoading(true);

            })
            .catch(() => {

            }).finally(() => {
                setIsLoading(false);
            })
    }

    const triggerSubmitHandler = () => form.submit();

    const onChange = value => {
        setFormState(value);
    }

    useEffect(() => {

        form.setFieldsValue({
            ...authContext?.patientData
        });
        // eslint-disable-next-line
    }, []);


    return <Spin tip="Loading..." spinning={isLoading} indicator={<LoadingOutlined size={'large'}/>}>


        <Row style={{padding: "0 10px"}}>
            <Col>
                <Form>
                    <Form.Item
                        label={'Edit mode: '}
                        name="name"
                    >
                        <Switch checked={formState} onChange={onChange}/>
                    </Form.Item>
                </Form>
            </Col>

            <Col xs={24} lg={24} xl={24}>

                <Form
                    disabled={!formState}
                    layout="vertical"
                    size={'middle'}
                    onFinish={onFinish}
                    form={form}
                    autoComplete="off"
                >

                    <Row gutter={[24, 24]}>


                        <Col xs={24} lg={8}>
                            <Form.Item
                                label={'Last name'}
                                name="last_name"
                                rules={[{
                                    required: true, message: 'Please input last name!',
                                }]}
                            >
                                <Input placeholder="Last name"/>
                            </Form.Item>
                        </Col>


                        <Col xs={24} lg={8}>
                            <Form.Item
                                label={'First name'}
                                name="first_name"
                                rules={[{
                                    required: true, message: 'Please input your first name!',
                                }]}
                            >
                                <Input placeholder="First name"/>
                            </Form.Item>
                        </Col>


                        <Col xs={24} lg={8}>
                            <Form.Item
                                label={'Date of birth'}
                                name="date_of_birth"
                                rules={[
                                    {required: true, message: 'Please input your date of birth!'},
                                    {
                                        pattern: /(\d{0,2})(\d{0,2})(\d{0,4})/,
                                        message: "Not date format",
                                        transform: v => form.setFieldValue('date_of_birth', dobMask(v)),
                                    },
                                    {
                                        required: true,
                                        message: "Invalid date format",
                                        validator: (rule, value) => {
                                            if (dayjs(value).isValid()) return Promise.resolve();
                                            return Promise.reject();
                                        }
                                    }
                                ]}
                            >
                                <Input placeholder="MM-DD-YYYY"/>
                            </Form.Item>
                        </Col>


                        <Col xs={24} lg={8}>
                            <Form.Item
                                label={'Email'}
                                name="email"
                                rules={[{required: true, message: 'Please input email!', type: 'email'}]}
                            >
                                <Input placeholder="Email"/>
                            </Form.Item>
                        </Col>


                        <Col xs={24} lg={8}>
                            <Form.Item
                                label={'Phone'}
                                name="phone"
                                rules={[
                                    {required: true, message: 'Please input phone!'},
                                    {
                                        pattern: /(\d{0,3})(\d{0,3})(\d{0,4})/,
                                        message: "Not valid",
                                        transform: v => form.setFieldValue('phone', phoneNumberMask(v)),
                                    }

                                ]}
                            >
                                <Input placeholder="Phone"/>
                            </Form.Item>
                        </Col>


                        <Col span={24}>
                            <Button
                                hidden={!formState}
                                style={{marginBottom: 50,}}
                                type="primary"
                                loading={false}
                                onClick={triggerSubmitHandler}
                            >
                                Update information
                            </Button>
                        </Col>

                    </Row>
                </Form>
            </Col>
        </Row>
    </Spin>
}

export default PersonalInformation;