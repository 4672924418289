/*
* This file it's main router config. All urls and components for this urls, texts for title and others in the future.
* Use this file firstly for creating new page.
* */
import NotFoundPage from "../pages/base/NotFoundPage";
import Login from "../pages/auth/Login";
import ResetPassword from "../pages/auth/ResetPassword";
import Logout from "../pages/auth/Logout";
import Register from "../pages/auth/Register";

import {AuthRequired} from "../pages/auth/AuthProvider";
import MyResults from "../pages/application/MyResults";
import ActivateKit from "../pages/application/ActivateKit";
import MyInformation from "../pages/application/MyInformation";

const pages = {

    'login': {
        url: '/login',
        title: 'Login',
        jsx: <Login/>,
    },

    'logout': {
        url: '/logout',
        title: 'Logout',
        jsx: <Logout/>,
    },

    'register': {
        url: '/create-new-account',
        title: 'Register',
        jsx: <Register/>,
    },

    'resetPassword': {
        url: '/reset-password',
        title: 'Reset password',
        jsx: <ResetPassword/>,
    },

    'notFoundPage': {
        url: '*',
        title: 'Page not found. 404',
        jsx: <NotFoundPage/>,
    },

    'home': {
        url: '/',
        title: 'My Results',
        jsx: <AuthRequired><MyResults/></AuthRequired>,
    },

    'myInformation': {
        url: '/my-information',
        title: 'Information',
        jsx: <AuthRequired><MyInformation/></AuthRequired>,
    },

    'activateKit': {
        url: '/activate-kit',
        title: 'Activate kit',
        jsx: <AuthRequired><ActivateKit/></AuthRequired>,
    },

};

export default pages;