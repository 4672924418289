import styled from "styled-components";
import React from "react";

/* This component using only on mobile devices */

const HeaderLogoDiv = styled.div`
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: start;
  margin: 0;
`
const HeaderLogoImg = styled.img`
  width: 60%;
  padding: 10px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
`


const HeaderLogoOnMobile = ({image_source}) =>
    <HeaderLogoDiv>
        <HeaderLogoImg src={image_source}/>
    </HeaderLogoDiv>


export default HeaderLogoOnMobile;