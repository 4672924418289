import React, {useEffect} from "react";
import restAPI from "../../../features/restAPI";

import {Col, Row, Button} from "antd";

const CheckTestCode = (props) => {
    /* In general, we need check current Kit test kind and use this step */

    const {detectedKitInfo, isLoading, next, detectedProduct, setDetectedProduct} = props;

    // hardcoded Courmed products
    const courmedProducts = [
        {
            "id": 1,
            "name": "Essentials Vitamins & Minerals",
            "price": 374,
            "code": "ME",
            "ordersCode": "EF.07",
            "travalabLink": "https://travalab.com/courmed-essential",
        },

        {
            "id": 2,
            "name": "Advanced Essentials + Hormones",
            "price": 674,
            "code": "MP",
            "ordersCode": "EF.08",
            "travalabLink": "https://travalab.com/courmed-plus",
        },

        {
            "id": 3,
            "name": "Comprehensive Advanced + Metabolic ++",
            "price": 1125,
            "code": "MC",
            "ordersCode": "EF.09",
            "travalabLink": "https://travalab.com/courmed-comprehensive",
        },
    ];

    useEffect(() => {

        if (!detectedProduct) {

            const [detectedProductItem] = courmedProducts.filter(p => p.code === detectedKitInfo.test);

            if (detectedProductItem) {
                setDetectedProduct({...detectedProductItem});  // important this guy using in Final Step for Courmed
            } else {
                throw Error(`We can't find product associated with your Kit. Please call to support.`);
            }
        }

        // eslint-disable-next-line
    }, [detectedProduct]);


    const CourmedTest = () =>

        <Row>
            <Col lg={24}>

                <p>You are about to activate your <strong>Courmed {detectedProduct?.name}</strong> test kit.</p>

                <p>
                    To begin the process for mobile phlebotomy, please click&nbsp;&nbsp;

                    <Button
                        size={'small'}
                        color={'primary'}
                        type={'link'}
                        target={'_blank'}
                        href={detectedProduct?.travalabLink}
                    >
                        THIS LINK
                    </Button>
                </p>

                <div style={{margin: "24px 0"}}>

                    <Button loading={isLoading} type="primary" onClick={next}>
                        Next
                    </Button>
                </div>

            </Col>
        </Row>


    return <CourmedTest/>;

}

export default CheckTestCode;