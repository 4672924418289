import dayjs from "dayjs";
import React, {useState, useEffect} from "react";
import {Button, Col, DatePicker, TimePicker, Row, Form} from 'antd';


const AddCollectionDate = ({next, collectionDate, setCollectionDate}) => {

    const [collectionDateState, setCollectionDateState] = useState(null);
    const [collectionTimeState, setCollectionTimeState] = useState(null);

    const onChangeDate = (value, dateString) => {
        console.log('Selected Date: ', dateString);
        // console.log('Selected Date: ', dayjs(collectionDateState).format('MM-DD-YYYY'));
        // console.log('Selected Time: ', collectionTimeState);
        // console.log('Selected Time: ', dayjs(collectionTimeState).format('MM-DD-YYYY'));


        // console.log('Selected Time: ', value);
        // console.log('Formatted Selected Time: ', dateString);


        setCollectionDateState(dateString);
    };

    const onChangeTime = (value, dateString) => {
        console.log('Selected Date: ', dateString);
        // console.log('Selected Date: ', dayjs(collectionDateState).format('MM-DD-YYYY'));
        // console.log('Selected Time: ', collectionTimeState);
        // console.log('Selected Time: ', dayjs(collectionTimeState).format('MM-DD-YYYY'));


        // console.log('Selected Time: ', value);
        // console.log('Formatted Selected Time: ', dateString);


        setCollectionTimeState(dateString);
    };

    const onOkDateHandler = value => setCollectionDateState(value);
    const onOkTimeHandler = value => setCollectionTimeState(value);

    useEffect(() => {
        console.log(collectionDate);
        setCollectionDate(null);
        console.log(collectionDate);

        let date = collectionDateState || "";
        let time = collectionTimeState || "";
        let current = dayjs(date + time, 'MM-DD-YYYY hh:mm A')


        if (date !== "" && time !== "") {
            console.log(current.toISOString());
            setCollectionDate(current);
        }

    }, [collectionDateState, collectionTimeState]);


    return <Row>
        <Col lg={24}>
            <p style={{margin: ' 0 0 30px 0'}}>
                <strong>
                    Add Collection Date
                </strong>
            </p>

            <Form.Item
                label={'Date'}
            >
                <DatePicker onChange={onChangeDate} format={'MM-DD-YYYY'}/>
            </Form.Item>

            <Form.Item
                label={'Time'}
            >
                <TimePicker onChange={onChangeTime} format={'hh:mm A'}/>
            </Form.Item>

            <div style={{margin: "24px 0"}}>
                <Button disabled={!collectionDate} type="primary" onClick={next}>
                    Next
                </Button>
            </div>

        </Col>
    </Row>
}

export default AddCollectionDate;